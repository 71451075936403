<template>
  <div>
    <b-field :label="$t('label_numbers_only')" v-if="showNumber()">
      <b-numberinput
        :controls="false"
        v-model="inspection.number"
        @input="updateMarkerNumber"
      />
    </b-field>
    <b-field :label="$t('measurement_name')" v-if="showMeasurementName()">
      <b-input v-model="inspection.measurementName" />
    </b-field>
    <b-field>
      <b-checkbox
        v-if="showDescriptionCheckbox()"
        v-model="inspection.hasDescription"
        @input="toogleDescription"
      >
        {{ $t('marker_description') }}
      </b-checkbox>
    </b-field>

    <b-dropdown
      v-if="inspection.hasDescription"
      v-model="inspection.descriptionDropdown"
      aria-role="list"
      @change="updatedescriptionDropdown"
    >
      <template #trigger>
        <div style="width: 160px">
          <b-button
            style="font-size: 12px"
            :label="getdescriptionDropdownName()"
            type="is-primary"
            :expended="true"
            outlined
            :expanded="true"
            icon-right="chevron-down"
          />
        </div>
      </template>

      <b-dropdown-item
        style="width: 160px"
        v-for="(option, index) in descriptionOptions"
        :key="index"
        :value="option.value"
        aria-role="listitem"
      >
        {{ option.name }}
      </b-dropdown-item>
    </b-dropdown>

    <b-field :label="$t('marker_description')" v-if="showDescriptionTextBox">
      <b-input v-model="inspection.description" />
    </b-field>
    <b-field :label="$t('units')" v-if="showUnits()">
      <b-field class="dij-diagrambuilder-inspection-unit">
        <b-radio-button
          v-model="inspection.unit"
          native-value="in"
          @input="clearIso"
        >
          <span>{{ $t('in') }}</span>
        </b-radio-button>

        <b-radio-button v-model="inspection.unit" native-value="mm">
          <span>{{ $t('mm') }}</span>
        </b-radio-button>
      </b-field>
    </b-field>
    <b-field :label="$t('iso_name')" v-if="showIso()">
      <b-select
        :placeholder="$t('iso_name')"
        v-model="inspection.isoName"
        @input="updateIsoGrades"
      >
        <option value="">{{ $t('none') }}</option>
        <option v-for="isoName in isoNames" :value="isoName" :key="isoName">
          {{ isoName }}
        </option>
      </b-select>
    </b-field>
    <b-field :label="$t('iso_grade')" v-if="showIso()">
      <b-select
        :placeholder="$t('iso_grade')"
        v-model="inspection.isoGrade"
        @input="updateTolerances"
      >
        <option v-for="isoGrade in isoGrades" :value="isoGrade" :key="isoGrade">
          {{ isoGrade }}
        </option>
      </b-select>
    </b-field>
    <b-field :label="$t('expected_value')" v-if="showExpectedValue()">
      <b-input
        type="number"
        step="any"
        v-model="inspection.expectedValue"
        @blur="updateTolerances"
      />
    </b-field>
    <b-field :label="$t('tolerance')" v-if="showTolerance()">
      <b-input type="number" step="any" v-model="inspection.tolerance" />
    </b-field>
    <b-field :label="$t('tolerance_type')" v-if="showTypeOfTolerance()">
      <b-field class="dij-diagrambuilder-inspection-tolerance-type">
        <b-radio-button
          v-model="inspection.toleranceType"
          native-value="normal"
        >
          <span>+ -</span>
        </b-radio-button>

        <b-radio-button
          v-model="inspection.toleranceType"
          native-value="positive"
        >
          <span>+ +</span>
        </b-radio-button>

        <b-radio-button
          v-model="inspection.toleranceType"
          native-value="negative"
        >
          <span>- -</span>
        </b-radio-button>
      </b-field>
    </b-field>
    <b-field :label="$t('text')" v-if="showText()">
      <b-input v-model="inspection.text" @input="updateText" />
    </b-field>
    <b-field :label="$t('text_size')" v-if="showTextSize()">
      <b-input v-model="inspection.textSize" @input="updateText" />
    </b-field>
    <b-field label="Image Size" v-if="showImageSize()">
      <b-slider
        :min="50"
        :max="1000"
        :step="10"
        v-model="inspection.textSize"
        lazy
        @input="updateImageSize"
      ></b-slider>
    </b-field>
    <b-field
      grouped
      class="dij-diagrambuilder-inspection-tolerances"
      v-if="showTypeOfTolerance()"
    >
      <b-field
        :label="
          inspection.toleranceType == 'normal' ||
          inspection.toleranceType == 'positive'
            ? $t('positive_tolerance')
            : $t('negative_tolerance')
        "
      >
        <b-input
          type="number"
          step="any"
          v-model="inspection.positiveTolerance"
          @blur="adjustToleranceValue(inspection)"
        />
      </b-field>
      <b-field
        :label="
          inspection.toleranceType == 'normal' ||
          inspection.toleranceType == 'negative'
            ? $t('negative_tolerance')
            : $t('positive_tolerance')
        "
      >
        <b-input
          type="number"
          step="any"
          v-model="inspection.negativeTolerance"
        />
      </b-field>
    </b-field>
    <b-field v-if="showMultipleMeasurements()">
      <b-checkbox
        v-model="inspection.multipleMeasurements"
        @input="toogleMultipleMeasurements"
      >
        {{ $t('multiple_positions') }}
      </b-checkbox>
    </b-field>
    <b-field
      class="dij-diagrambuilder-inspection-measurementcount"
      v-if="inspection.multipleMeasurements"
    >
      <b-slider
        :min="2"
        :max="12"
        v-model="inspection.measurementCount"
        @change="updateMeasurementCount()"
      ></b-slider>
      {{ inspection.measurementCount }}
    </b-field>
    <b-field :label="$t('direction')" v-if="showDirection()">
      <b-field class="dij-diagrambuilder-inspection-direction">
        <b-radio-button v-model="inspection.directionalUnit" native-value="deg">
          <span>{{ $t('degrees') }}</span>
        </b-radio-button>

        <b-radio-button v-model="inspection.directionalUnit" native-value="clk">
          <span>{{ $t('clock') }}</span>
        </b-radio-button>
      </b-field>
    </b-field>
    <b-field :label="$t('orientation')" v-if="showOrientation()">
      <b-field class="dij-diagrambuilder-inspection-orientation">
        <b-radio-button
          v-model="inspection.orientation"
          native-value="up"
          @input="updateMarkerOrientation"
        >
          <b-icon icon="chevron-down" />
        </b-radio-button>

        <b-radio-button
          v-model="inspection.orientation"
          native-value="down"
          @input="updateMarkerOrientation"
        >
          <b-icon icon="chevron-up" />
        </b-radio-button>

        <b-radio-button
          v-model="inspection.orientation"
          native-value="right"
          @input="updateMarkerOrientation"
        >
          <b-icon icon="chevron-left" />
        </b-radio-button>

        <b-radio-button
          v-model="inspection.orientation"
          native-value="left"
          @input="updateMarkerOrientation"
        >
          <b-icon icon="chevron-right" />
        </b-radio-button>
      </b-field>
    </b-field>
    <b-field :label="$t('orientation')" v-if="showTextOrientation()">
      <b-field class="dij-diagrambuilder-inspection-orientation">
        <b-button class="rotate-button" @click="updateTextOrientation">
          {{ $t('rotate_text') }}
        </b-button>
      </b-field>
    </b-field>
    <b-field v-if="showEditTable()">
      <b-button @click="clickMe">{{ $t('edit_table') }}</b-button>
    </b-field>
  </div>
</template>

<script>
import {
  GET_ISO_NAMES,
  GET_ISO_GRADES,
  GET_ISO_VALUES,
} from '../../store/iso/getters/getterTypes';
import {
  GET_ISO_NAMES_ACTION,
  GET_ISO_GRADES_ACTION,
  GET_ISO_VALUES_ACTION,
  CLEAR_ISO_GRADES_ACTION,
} from '../../store/iso/actions/actionTypes';

import DESCRIPTION_OPTIONS from './DescriptionOptions';

const EMPTY_MEASUREMENT_VALUE = {
  value: '',
  images: [],
  comment: '',
};

export default {
  name: 'inspection-detail',
  props: ['value'],
  computed: {
    inspection: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isoNames() {
      const isoNames = this.$store.getters[GET_ISO_NAMES];
      return isoNames.sort();
    },
    isoGrades() {
      return this.$store.getters[GET_ISO_GRADES];
    },
    isoValues() {
      return this.$store.getters[GET_ISO_VALUES];
    },
  },

  mounted() {
    if (
      this.inspection.hasDescription &&
      this.inspection.descriptionDropdown === 'other'
    ) {
      this.showDescriptionTextBox = true;
    }
  },
  data() {
    return {
      posTol: 0.0005,
      descriptionOptions: DESCRIPTION_OPTIONS,
      showDescriptionTextBox: false,
      orientations: ['up', 'right', 'down', 'left'],
      currentOrientationIndex: 0,
    };
  },
  async created() {
    if (!this.inspection.isoName) {
      this.inspection.isoName = '';
    }
    if (!this.isoNames || this.isoNames.length === 0) {
      this.$store.dispatch(GET_ISO_NAMES_ACTION);
    }
    if (this.inspection.isoName && this.inspection.isoGrade) {
      this.$store.dispatch(GET_ISO_GRADES_ACTION, this.inspection.isoName);
    }
  },
  methods: {
    countDecimalPlaces(value) {
      if (!value) {
        return 0;
      }

      const numString = typeof value === 'number' ? value.toString() : value;

      const parts = numString.split('.');

      if (parts.length === 2) {
        return parts[1].length;
      }

      return 0;
    },
    adjustToleranceValue(inspection) {
      const numberOfDecimals = this.countDecimalPlaces(
        inspection.expectedValue
      );

      const adjustTolerance = (tolerance) => {
        return tolerance !== undefined
          ? parseFloat(tolerance || 0).toFixed(numberOfDecimals)
          : tolerance;
      };

      inspection.positiveTolerance = adjustTolerance(
        inspection.positiveTolerance
      );
      inspection.negativeTolerance = adjustTolerance(
        inspection.negativeTolerance
      );
    },
    showNumber() {
      return (
        this.inspection.type !== 'text' &&
        this.inspection.type !== 'arrow' &&
        this.inspection.type !== 'line' &&
        this.inspection.type !== 'position-image' &&
        this.inspection.type !== 'table'
      );
    },
    showUnits() {
      return (
        this.inspection.type === 'diameter' ||
        this.inspection.type === 'runout' ||
        this.inspection.type === 'length'
      );
    },
    showIso() {
      return (
        this.inspection.unit === 'mm' &&
        (this.inspection.type === 'diameter' ||
          this.inspection.type === 'length')
      );
    },
    showExpectedValue() {
      return (
        this.inspection.type === 'diameter' || this.inspection.type === 'length'
      );
    },
    showTolerance() {
      return this.inspection.type === 'runout';
    },
    showTextOrientation() {
      return this.inspection.type === 'text';
    },
    showText() {
      return this.inspection.type === 'text';
    },
    showTextSize() {
      return this.inspection.type === 'text';
    },
    showImageSize() {
      return this.inspection.type === 'position-image';
    },
    showEditTable() {
      return this.inspection.type === 'table';
    },
    showTypeOfTolerance() {
      return (
        this.inspection.type === 'diameter' || this.inspection.type === 'length'
      );
    },
    showMultipleMeasurements() {
      return (
        this.inspection.type !== 'visual' &&
        //  this.inspection.type !== 'generic' &&
        this.inspection.type !== 'text' &&
        this.inspection.type !== 'arrow' &&
        this.inspection.type !== 'freefinding' &&
        this.inspection.type !== 'line' &&
        this.inspection.type !== 'position-image' &&
        this.inspection.type !== 'table'
      );
    },
    showOrientation() {
      return (
        this.inspection.type !== 'length' &&
        this.inspection.type !== 'text' &&
        this.inspection.type !== 'arrow' &&
        this.inspection.type !== 'freefinding' &&
        this.inspection.type !== 'line' &&
        this.inspection.type !== 'table'
      );
    },
    showDirection() {
      return (
        this.inspection.type === 'runout' &&
        !this.inspection.multipleMeasurements
      );
    },
    showMeasurementName() {
      return this.inspection.type === 'generic';
    },
    showVisualName() {
      return this.inspection.type === 'visual';
    },
    showDescriptionCheckbox() {
      return (
        this.inspection.type !== 'text' &&
        this.inspection.type !== 'arrow' &&
        this.inspection.type !== 'line' &&
        this.inspection.type !== 'position-image'
      );
    },
    getdescriptionDropdownName() {
      const value = this.inspection.descriptionDropdown;
      const options = DESCRIPTION_OPTIONS.find(
        (option) => option.value === value
      );
      return options.name;
    },
    updatedescriptionDropdown(value) {
      const option = DESCRIPTION_OPTIONS.find((op) => op.value === value);
      this.inspection.description = option.name;

      if (option.value === 'other') {
        this.showDescriptionTextBox = true;
        this.inspection.description = '';
      } else {
        this.showDescriptionTextBox = false;
      }
    },
    toogleDescription(value) {
      if (value) {
        this.inspection.descriptionDropdown = DESCRIPTION_OPTIONS[0].value;
        this.inspection.description = DESCRIPTION_OPTIONS[0].name;
      } else {
        this.inspection.description = '';
        this.inspection.descriptionDropdown = undefined;
      }
    },
    updateMarkerNumber() {
      if (this.inspection.number) {
        const label = this.inspection.marker.children[2];
        label.content = this.inspection.number;
        // This keeps the text inside the bubble centered
        if (this.inspection.type === 'length')
          label.position = this.inspection.marker.children[1].position;
        else label.position = this.inspection.marker.children[1].position;
      }
    },
    countDecimals(value) {
      const stringValue = String(value).replace(',', '.');
      const decimalPart = stringValue.split('.')[1];
      return decimalPart ? decimalPart.length : 0;
    },
    async updateTolerances() {
      const expectedValue = this.inspection.expectedValue || '0.0';
      if (
        this.inspection.unit === 'mm' &&
        this.inspection.isoName &&
        this.inspection.isoGrade
      ) {
        await this.$store.dispatch(GET_ISO_VALUES_ACTION, {
          name: this.inspection.isoName,
          grade: this.inspection.isoGrade,
          values: [expectedValue],
        });

        const tolerance1 = this.isoValues[0]?.tolerance1 || 0;
        const tolerance2 = this.isoValues[0]?.tolerance2 || 0;
        if (tolerance1 > 0 && tolerance2 > 0) {
          this.inspection.toleranceType = 'positive';
        } else if (tolerance1 < 0 && tolerance2 < 0) {
          this.inspection.toleranceType = 'negative';
        } else {
          this.inspection.toleranceType = 'normal';
        }
        this.inspection.positiveTolerance = Math.abs(tolerance1);
        this.inspection.negativeTolerance = Math.abs(tolerance2);

        this.inspection.positiveTolerance = parseFloat(
          this.inspection.positiveTolerance
        );
        this.inspection.negativeTolerance = parseFloat(
          this.inspection.negativeTolerance
        );
      } else {
        const decimalsCount = this.countDecimals(expectedValue);
        this.inspection.positiveTolerance = parseFloat(
          this.inspection.positiveTolerance
        ).toFixed(decimalsCount);
        this.inspection.negativeTolerance = parseFloat(
          this.inspection.negativeTolerance
        ).toFixed(decimalsCount);
      }
    },
    updateMarkerOrientation() {
      if (this.inspection.type === 'position-image') {
        this.$parent.makePositionImageMarker(this.inspection);
      } else {
        this.$parent.makeSimpleMarker(this.inspection);
      }
    },
    updateTextOrientation() {
      switch (this.inspection.orientation) {
        case 'up':
          this.inspection.orientation = 'right';
          break;
        case 'right':
          this.inspection.orientation = 'down';
          break;
        case 'down':
          this.inspection.orientation = 'left';
          break;
        case 'left':
          this.inspection.orientation = 'up';
          break;
        default:
          this.inspection.orientation = 'up';
      }
      this.$parent.makeTextMarker(this.inspection);
    },
    updateText() {
      if (!this.inspection.text) {
        this.inspection.text = this.inspection.number;
      }
      if (!this.inspection.textSize) {
        this.inspection.text = 18;
      }
      this.inspection.marker.children[0].content = this.inspection.text;
      this.inspection.marker.children[0].fontSize = this.inspection.textSize;
    },

    updateImageSize() {
      if (!this.inspection.textSize) {
        this.inspection.text = 150;
      }
      this.$parent.makePositionImageMarker(this.inspection);
    },
    toogleMultipleMeasurements() {
      if (
        !this.inspection.multipleMeasurements &&
        this.inspection.type === 'runout'
      ) {
        this.inspection.measurementCount = 2;
      } else {
        this.inspection.measurementCount = 2;
      }

      if (
        this.inspection.inTable &&
        !this.inspection.multipleMeasurements &&
        this.inspection.type !== 'runout'
      ) {
        this.inspection.measurementCount = 1;
      }

      this.updateMeasurementCount();
    },
    updateMeasurementCount() {
      if (this.inspection.inTable) {
        if (this.inspection.type === 'runout') {
          if (this.inspection.multipleMeasurements) {
            this.inspection.tableMeasurements.forEach((tMeasurment) => {
              for (
                let index = 0;
                index < this.inspection.measurementCount;
                index++
              ) {
                if (!tMeasurment[index]) {
                  tMeasurment[index] = EMPTY_MEASUREMENT_VALUE;
                }
              }
            });
          }
        } else {
          this.inspection.tableMeasurements.forEach((tableMeasurements) => {
            for (
              let index = 0;
              index < this.inspection.measurementCount;
              index++
            ) {
              if (!tableMeasurements[index]) {
                tableMeasurements[index] = EMPTY_MEASUREMENT_VALUE;
              }
            }
          });
        }
      } else {
        this.inspection.measurements.length = this.inspection.measurementCount;
        for (let index = 0; index < this.inspection.measurementCount; index++) {
          if (!this.inspection.measurements[index]) {
            this.inspection.measurements[index] = EMPTY_MEASUREMENT_VALUE;
          }
        }
      }
    },
    updateIsoGrades() {
      if (this.inspection.isoName) {
        this.$store.dispatch(GET_ISO_GRADES_ACTION, this.inspection.isoName);
      } else {
        this.clearIso();
      }
    },
    clearIso() {
      this.inspection.isoName = '';
      this.inspection.isoGrade = null;
      this.$store.dispatch(CLEAR_ISO_GRADES_ACTION);
    },
  },
};
</script>

<style lang="scss" scoped>
.dij-diagrambuilder-inspection-unit {
  .control {
    flex: 0 0 50%;
  }
}
.dij-diagrambuilder-inspection-tolerance-type {
  .control {
    flex: 0 0 calc(100% / 3);
  }
}
.dij-diagrambuilder-inspection-orientation {
  .control {
    flex: 0 0 25%;
  }
}
.dij-diagrambuilder-inspection-tolerances {
  ::v-deep div.control input.input {
    width: 70px;
  }
}
.dij-diagrambuilder-inspection-measurementcount {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.rotate-button {
  font-size: 75% !important;
  width: 100% !important;
}
// lower the text size for label and fields
.field ::v-deep label,
.control ::v-deep input {
  font-size: 75%;
}
// Disable the input number arrows
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type='number'] {
  -moz-appearance: textfield;
}
</style>
